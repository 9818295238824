import customTooltips from './customTooltips'

let chart
let gradientStroke
/* 描画 */
const drawLineChart = (dataObject) => {
  //dom取得とサイズ取得。id名などは文字列連結で
  let tabIndex = dataObject.tabIndex
  if(dataObject.tabIndex !== "") {
    tabIndex = dataObject.tabIndex + 1
  }
  const ctx = document.getElementById("chart" + tabIndex).getContext('2d')
  const height = document.getElementById("chart-wrapper" + tabIndex).innerHeight || document.getElementById("chart-wrapper" + tabIndex).clientHeight
  const width = document.getElementById("chart-wrapper" + tabIndex).innerWidth || document.getElementById("chart-wrapper" + tabIndex).clientWidth

  //チャートの共通グラデーション設定
  gradientStroke = ctx.createLinearGradient(0, 0, 0, height)
  gradientStroke.addColorStop(0, "rgba(255,136,136,1)")
  gradientStroke.addColorStop(1, "rgba(63,201,159,0.33)")

  if (chart) {
    chart.destroy()
  }
  chart = new Chart(ctx, {
    type: 'line',
    data: {
      labels: dataObject.labelX,
      datasets: getDatasets(dataObject.lines)
    },
    options: {
      scales: {
        yAxes: [{
          display: false, 
            ticks: {
                beginAtZero:true
            }
        }],
        xAxes: [{
            gridLines: {
              borderDash: [2, 8],
              color: 'rgb(126,125,130)'
            },
            ticks: {
              maxTicksLimit: 16,
              maxRotation: 0,
              minRotation: 0,
              autoSkip: false,
              callback: function(value, index, values) {
                if (index % 3 !== 0) {
                    return "";
                }
                return value;
              }
            },
        }]
      },
      legend: {
            display: false
      },
      tooltips: {
        enabled: false,
        mode: 'point',
        position: 'nearest',
        custom: customTooltips
      },
      layout: {
        padding: {
            left: 0,
            right: 0,
            top: 15,
            bottom: 0
        }
      }
    }
  })
  document.getElementById("chart-tip").style.display ="none";
  chart.update();
}
/* 描画ここまで */

/* chart.js用のdatasetsを返す */
const getDatasets = (lines) => {
  let dataArray = [];
  lines.forEach( line => {
    dataArray.push({
      data: line.data,
      backgroundColor: gradientStroke,
      borderColor: 'rgb(239,143,144)',
      pointBackgroundColor: 'rgb(255,255,255)',
      pointBorderWidth: 3,
      pointRadius: 3,
      pointBorderColor: 'rgb(239,143,144)',
      borderWidth: 3
    })
  })
  return dataArray
}

export default drawLineChart