import $ from 'jquery'

const $sdgs = $("#sdgs-tooltip")
const $title = $sdgs.find("#sdgs-tooltip__title")
const $text = $sdgs.find("#sdgs-tooltip__description")

// SDGsのモーダルのテキスト
const textArray = [
  {
    title: "すべての人々に包括的かつ公平で質の高い教育を提供し、生涯学習の機会を推進する",
    description: "環境価値の販売を子育て支援に役立てるため、 地域の図書館、学校などに絵本や書籍などを寄付していきます。 また、地域の子ども食堂などにも支援の輪を広げていきます。"
  },
  {
    title: "すべての人々に手ごろで信頼でき、持続可能かつ近代的なエネルギーへのアクセスを確保する",
    description: "発電時にCO2を発生しない太陽光発電設備で作った クリーンな 電気を全国に普及していくと共に、電力の地産地消を推進します。"
  },
  {
    title: "気候変動とその影響に立ち向かうため、緊急対策を取る",
    description: "地球温暖化対策の一手段としてCO2 の排出量を抑えるために、太陽光発電設備やLEDに代表されるクリーンエネルギー、省エネ設備の普及に取り組んでいきます。"
  },
  {
    title: "陸上生態系の保護、回復および持続可能な利用の推進、森林の持続可能な管理、砂漠化への対処、土地劣化の阻止及び逆転、ならびに生物多様性損失の阻止を図る",
    description: "環境価値の販売を通して苗木等を植林事業体に提供して、豊かな緑を維持し環境改善としての地球温暖化対策に取り組みます。"
  },
  {
    title: "持続可能な開発に向けて実施手段を強化し、グローバル・パートナーシップを活性化する",
    description: "個人、法人、事業の大小に関係なく、同じ目的を持ったパートナーシップで地球温暖化対策を推し進めていきます。"
  }
]

// トップページ用SDGsのモーダルのテキスト
const topText = {
  title: "SDGs（持続可能な開発目標）とは？",
  description: "SDGs（持続可能な開発目標）とは、2015年9月の国連サミットで採択された2030年までの国際目標で、持続可能な世界を実現するための17のゴール・169のターゲットから構成されています。"
}


export const initSDGs = () => {
  const $button = $sdgs.find("#sdgs-tooltip__button")
  $button.on("click", () => {
    $sdgs.fadeOut(250, () => {
      $title.html("")
      $text.html("")
    });
  });
}

export const showSDGsModal = (num = 0) => {
  const targetText = num < textArray.length ? textArray[num] : textArray[0]
  $title.html(targetText.title)
  $text.html(targetText.description)
  $sdgs.fadeIn(250)
}

export const showTopSDGsModal = () => {
  $title.html(topText.title)
  $text.html(topText.description)
  $sdgs.fadeIn(250)
}