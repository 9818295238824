const customTooltips = function(tooltip) {
  /* ツールチップ初期設定 */
  Chart.defaults.global.pointHitDetectionRadius = 1
  // Tooltip Element
  const tooltipEl = document.getElementById('chart-tip')

  if (!tooltipEl) {
    tooltipEl = document.createElement('div')
    tooltipEl.id = 'chart-tip'
    tooltipEl.innerHTML = '<table></table>'
    this._chart.canvas.parentNode.appendChild(tooltipEl)
  }

  // Hide if no tooltip
  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = 1
    return
  }

  // Set caret Position
  tooltipEl.classList.remove('above', 'below', 'no-transform')
  if (tooltip.yAlign) {
    tooltipEl.classList.add(tooltip.yAlign)
  } else {
    tooltipEl.classList.add('no-transform')
  }

  const getBody = (bodyItem) => {
    return bodyItem.lines
  }

  // Set Text
  if (tooltip.body) {
    let innerHtml = ''

    if (tooltip.title) {
      var ym = tooltip.title[0];
      var y  = ym.substr(0, 4);
      var m  = month_name_to_num(ym.substr(5));

      innerHtml += '<tr><td>' + y + '年' + m + '月</td></tr><tr><td>CO2削減量</td></tr>'
    }

    const bodyLines  = tooltip.body.map(getBody)
    bodyLines.forEach(function(body, i) {
      if(body[i]){
        let displayNum = body[i].toString().indexOf(':') ? body[i].toString().split(':').slice(-1)[0] : body[i].toString()
        if (i == 0) {
          innerHtml += '<tr><td>' + Number(displayNum).toFixed(2)+ '</td></tr>'
        }
      }
    })

    const tableRoot = tooltipEl.querySelector('table')
    tableRoot.innerHTML = innerHtml
  }

  const positionY = this._chart.canvas.offsetTop
  const positionX = this._chart.canvas.offsetLeft

  // Display, position, and set styles for font
  tooltipEl.style.opacity = 1
  tooltipEl.style.display = "inline-block"
  tooltipEl.style.left = positionX + tooltip.caretX + 'px'
  tooltipEl.style.top = positionY + tooltip.caretY + 'px'
  
  //リサイズ時に残っていたツールチップを非表示に
  window.addEventListener( 'resize', function() {
    tooltipEl.style.display ="none";
  }, false );
}
/* ツールチップ初期設定ここまで */

export default customTooltips
