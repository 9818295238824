import $ from 'jquery'

let $button
const toTop = () => {
  $('body, html')
    .stop()
    .animate({ scrollTop: 0 }, 500)
}

const handleScroll = () => {
  const posY = window.scrollY || window.pageYOffset
  if (posY > 50) {
    // スクロールしてる判定
    $button.addClass('scrolled')
    if (
      posY >=
      document.documentElement.scrollHeight - window.innerHeight - 150
    ) {
      //1番下までスクロール
      $button.addClass('scrollMax')
    } else {
      //まだ
      $button.removeClass('scrollMax')
    }
  } else {
    //スクロールしてない判定
    $button.removeClass('scrolled')
  }
}

const initTopButton = (btn) => {
  $button = btn
  $button.on("click", () => toTop())
  $(window).on('scroll', () => handleScroll())
  //アンカーリンクの遷移も併せて設定
  $("a[href^=\"#\"]").click(function(e) {
    e.preventDefault()
    // アンカーの値取得
    var href = $(this).attr("href")
    var position = $(href).offset().top
    $("html, body")
        .stop()
        .animate({ scrollTop: position }, 500, "swing")
});
}
export default initTopButton