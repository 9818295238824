import $ from 'jquery'

const setInfomationsImage = () => {
  $(function(){
    $(".information-item__image__inner").each((idx, elem) => {
      const imgPath = 'url(' + $(elem).find('img').eq(0).attr("src") + ')'
      $(elem).css({'background-image': imgPath})
    })
  })
}
export default setInfomationsImage