import $ from 'jquery'
import Chart from 'chart.js'

import drawLineChart from './drawLineChart'
import initTopButton from './topButton'
import infoImage from './infoImage'
import * as SDGs from './sdgs'

//トップに戻るボタン
initTopButton($('#button-top'));

//ログインボタン
$("#btn-login").on("click", function() {
  $("#sp-menu").toggleClass("open");
})

//「お知らせ」の画像を調整
infoImage()

//SDGsのモーダル設定。トップページは固定文言
SDGs.initSDGs()
$("img.has-modal").on("click", function() {
  SDGs.showTopSDGsModal()
})

window.$ = $;
window.drawLineChart = drawLineChart;